import React from 'react';
import { createRoot } from 'react-dom/client';
import { FilterContextProvider, UserContextProvider } from 'context';
import getPageComponents from 'reactEnvironmentInit';
import * as components from 'components';

// main stylesheet
import 'styles/index.scss';

// external global js
import 'bootstrap/dist/js/bootstrap.bundle';

// Font Awesome initialization
import 'fontAwesomeInit';

// Sentry initialization
import 'sentryInit';

const { ErrorBoundary } = components;

const pageComponents = getPageComponents(components);

const AppRoot = () => (
  <ErrorBoundary>
    <UserContextProvider>
      <FilterContextProvider>
        {pageComponents}
      </FilterContextProvider>
    </UserContextProvider>
  </ErrorBoundary>
);

const container = document.getElementById('react-root');
const root = createRoot(container);
root.render(<AppRoot />);
